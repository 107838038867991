import { useEffect, useState } from "react";
import Select from "react-select";
import { GetOther, GetSingleApi, PostApi, PutApi } from "../api/conection";

const paises = [
  { codigo: "us", nombre: "Estados Unidos", codigoTelefono: "+1" },
  { codigo: "ar", nombre: "Argentina", codigoTelefono: "+54" },
  { codigo: "au", nombre: "Australia", codigoTelefono: "+61" },
  { codigo: "br", nombre: "Brasil", codigoTelefono: "+55" },
  { codigo: "cl", nombre: "Chile", codigoTelefono: "+56" },
  { codigo: "mx", nombre: "México", codigoTelefono: "+52" },
  { codigo: "pe", nombre: "Perú", codigoTelefono: "+51" },
  { codigo: "fr", nombre: "Francia", codigoTelefono: "+33" },
  { codigo: "de", nombre: "Alemania", codigoTelefono: "+49" },
  { codigo: "it", nombre: "Italia", codigoTelefono: "+39" },
  { codigo: "nl", nombre: "Países Bajos", codigoTelefono: "+31" },
  { codigo: "es", nombre: "España", codigoTelefono: "+34" },
  { codigo: "ch", nombre: "Suiza", codigoTelefono: "+41" },
  { codigo: "se", nombre: "Suecia", codigoTelefono: "+46" },
  { codigo: "no", nombre: "Noruega", codigoTelefono: "+47" },
  { codigo: "dk", nombre: "Dinamarca", codigoTelefono: "+45" },
  { codigo: "fi", nombre: "Finlandia", codigoTelefono: "+358" },
  { codigo: "ru", nombre: "Rusia", codigoTelefono: "+7" },
  { codigo: "jp", nombre: "Japón", codigoTelefono: "+81" },
  { codigo: "cn", nombre: "China", codigoTelefono: "+86" },
  { codigo: "in", nombre: "India", codigoTelefono: "+91" },
  { codigo: "kr", nombre: "Corea del Sur", codigoTelefono: "+82" },
  { codigo: "il", nombre: "Israel", codigoTelefono: "+972" },
  { codigo: "sa", nombre: "Arabia Saudita", codigoTelefono: "+966" },
  { codigo: "za", nombre: "Sudáfrica", codigoTelefono: "+27" },
  { codigo: "eg", nombre: "Egipto", codigoTelefono: "+20" },
  { codigo: "ng", nombre: "Nigeria", codigoTelefono: "+234" },
  { codigo: "ke", nombre: "Kenia", codigoTelefono: "+254" },
  { codigo: "tr", nombre: "Turquía", codigoTelefono: "+90" },
  { codigo: "gr", nombre: "Grecia", codigoTelefono: "+30" },
  { codigo: "cz", nombre: "República Checa", codigoTelefono: "+420" },
  { codigo: "pl", nombre: "Polonia", codigoTelefono: "+48" },
  { codigo: "ua", nombre: "Ucrania", codigoTelefono: "+380" },
  { codigo: "hu", nombre: "Hungría", codigoTelefono: "+36" },
  { codigo: "at", nombre: "Austria", codigoTelefono: "+43" },
  { codigo: "be", nombre: "Bélgica", codigoTelefono: "+32" },
  { codigo: "pt", nombre: "Portugal", codigoTelefono: "+351" },
  { codigo: "ie", nombre: "Irlanda", codigoTelefono: "+353" },
  { codigo: "gb", nombre: "Reino Unido", codigoTelefono: "+44" },
  { codigo: "nz", nombre: "Nueva Zelanda", codigoTelefono: "+64" },
  { codigo: "sg", nombre: "Singapur", codigoTelefono: "+65" },
  { codigo: "my", nombre: "Malasia", codigoTelefono: "+60" },
  { codigo: "id", nombre: "Indonesia", codigoTelefono: "+62" },
  { codigo: "ph", nombre: "Filipinas", codigoTelefono: "+63" },
  { codigo: "vn", nombre: "Vietnam", codigoTelefono: "+84" },
];

function Configuration() {
  const [cambiarContraseña, setCambiarContraseña] = useState({
    contraseñaActual: "",
    nuevaContraseña: "",
    confirmarContraseña: "",
    nuevoNombreUsuario: "",
  });
  const [userData, setUserData] = useState({
    id: null,
    image: null,
    firstname: "",
    lastname: "",
    description: "",
    email: "",
    codigo_telefono: "",
    phone: "",
    country: "",
    province: "",
    district: "",
    instagram: "",
    youtube: "",
    facebook: "",
    website: "",
    linkedin: "",
    behance: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userFromStorage = JSON.parse(localStorage.getItem("user_id"));
        const res = await GetSingleApi("/user", userFromStorage);
        const user = res.data;
        setUserData({
          id: user?.id,
          username: user?.username,
          image: user?.image,
          firstname: user?.firstname,
          lastname: user?.lastname,
          description: user?.description,
          email: user?.email,
          codigo_telefono: user?.codigo_telefono,
          phone: user?.phone,
          country: user?.country,
          province: user?.province,
          district: user?.district,
          instagram: user?.instagram,
          youtube: user?.youtube,
          facebook: user?.facebook,
          website: user?.website,
          linkedin: user?.linkedin,
          behance: user?.behance,
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const handleCambiarContraseña = (e) => {
    e.preventDefault();
    // Aquí iría la lógica para cambiar la contraseña
  };

  const handleSubmitImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", userData.image);
    formData.append("_method", "PUT");
    try {
      await PostApi(`/user/update-profile-image/${userData.id}`, formData)
        .then(() => window.location.reload())
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await GetOther(
        `https://api.opencagedata.com/geocode/v1/json?q=${userData.district.replace(
          /\s+/g,
          "%20"
        )}%20${userData.province.replace(
          /\s+/g,
          "%20"
        )}%20${userData.country.replace(
          /\s+/g,
          "%20"
        )}&key=a08ab980938843789855fc7e05f161d3&no_annotations=1&language=es`
      )
        .then((res) =>
          setUserData({
            ...userData,
            coordenadas: `${res.data.results.geometry.lat}, ${res.data.results.geometry.lng}`,
          })
        )
        .catch((err) => console.error(err));
      await PutApi("/user", userData.id, userData)
        .then(() => window.location.reload())
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  const handleCambiarPerfil = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setUserData({ ...userData, image: file });
  };

  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
      }}
    >
      <h1 style={{ textAlign: "center", color: "#000" }}>NEAR-U</h1>
      <div className="row justify-content-center">
        <div
          className="col-lg-8"
          style={{
            paddingBottom: 50,
          }}
        >
          <h1
            className="mt-5 mb-4"
            style={{ color: "#fff", textAlign: "center" }}
          >
            Configuración
          </h1>

          <h2 style={{ color: "#fff" }}>Editá acá tu foto de perfil</h2>
          <form onSubmit={handleSubmitImage}>
            <div className="mb-3">
              <label
                htmlFor="imagenPerfil"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Imagen de Perfil:
              </label>
              <input
                type="file"
                className="form-control"
                id="imagenPerfil"
                onChange={handleCambiarPerfil}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Guardar Cambios
            </button>
          </form>

          <br />
          <hr style={{ borderColor: "#fff" }} />
          <br />

          <h2 style={{ color: "#fff" }}>Editá acá tu perfil</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label
                htmlFor="nuevoNombreUsuario"
                className="form-label                "
                style={{ color: "#fff" }}
              >
                Nombre de Usuario:
              </label>
              <input
                type="text"
                className="form-control"
                id="nuevoNombreUsuario"
                value={userData.username}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    username: e.target.value,
                  })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="nuevoNombre"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Nombre:
              </label>
              <input
                type="text"
                className="form-control"
                id="nuevoNombre"
                value={userData.firstname}
                onChange={(e) =>
                  setUserData({ ...userData, firstname: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="lastname"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Apellido:
              </label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                value={userData.lastname}
                onChange={(e) =>
                  setUserData({ ...userData, lastname: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={userData.email}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="description"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Descripción:
              </label>
              <textarea
                className="form-control"
                id="description"
                cols="30"
                rows="10"
                value={userData.description}
                onChange={(e) =>
                  setUserData({ ...userData, description: e.target.value })
                }
                required
              ></textarea>
            </div>
            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Telefono:
              </label>
              <div className="col d-flex align-items-center">
                <Select
                  options={paises.map((pais) => ({
                    value: pais,
                    label: (
                      <div className="d-flex align-items-center gap-1">
                        <img
                          src={`https://flagpedia.net/data/flags/w580/${pais.codigo}.webp`}
                          alt={`Bandera Pais ${pais.codigo}`}
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                          }}
                        />
                        ({pais.codigoTelefono})
                      </div>
                    ),
                  }))}
                  value={paises.find(
                    (pais) => pais.codigoTelefono == userData.codigo_telefono
                  )}
                  onChange={(selectedOption) => {
                    setUserData({
                      ...userData,
                      codigo_telefono: selectedOption.value.codigoTelefono,
                    });
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={userData.phone}
                  onChange={(e) =>
                    setUserData({ ...userData, phone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="pais"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Pais:
              </label>
              <input
                type="pais"
                className="form-control"
                id="pais"
                value={userData.country}
                onChange={(e) =>
                  setUserData({ ...userData, country: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="provincia"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Provincia:
              </label>
              <input
                type="provincia"
                className="form-control"
                id="provincia"
                value={userData.province}
                onChange={(e) =>
                  setUserData({ ...userData, province: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="distrito"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Distrito:
              </label>
              <input
                type="distrito"
                className="form-control"
                id="distrito"
                value={userData.district}
                onChange={(e) =>
                  setUserData({ ...userData, district: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="instagram"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Instagram:
              </label>
              <input
                type="instagram"
                className="form-control"
                id="instagram"
                value={userData.instagram}
                onChange={(e) =>
                  setUserData({ ...userData, instagram: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="youtube"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Youtube:
              </label>
              <input
                type="youtube"
                className="form-control"
                id="youtube"
                value={userData.youtube}
                onChange={(e) =>
                  setUserData({ ...userData, youtube: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="facebook"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Facebook:
              </label>
              <input
                type="facebook"
                className="form-control"
                id="facebook"
                value={userData.facebook}
                onChange={(e) =>
                  setUserData({ ...userData, facebook: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="website"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Sitio Web:
              </label>
              <input
                type="website"
                className="form-control"
                id="website"
                value={userData.website}
                onChange={(e) =>
                  setUserData({ ...userData, website: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="linkedin"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Linkedin:
              </label>
              <input
                type="linkedin"
                className="form-control"
                id="linkedin"
                value={userData.linkedin}
                onChange={(e) =>
                  setUserData({ ...userData, linkedin: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="behance"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Behance:
              </label>
              <input
                type="behance"
                className="form-control"
                id="behance"
                value={userData.behance}
                onChange={(e) =>
                  setUserData({ ...userData, behance: e.target.value })
                }
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Guardar Cambios
            </button>
          </form>

          <br />
          <hr style={{ borderColor: "#fff" }} />
          <br />

          <h2 style={{ color: "#fff" }}>Cambiar Contraseña</h2>
          <form onSubmit={handleCambiarContraseña}>
            <div className="mb-3">
              <label
                htmlFor="contraseñaActual"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Contraseña Actual:
              </label>
              <input
                type="password"
                className="form-control"
                id="contraseñaActual"
                value={userData.contraseñaActual}
                onChange={(e) =>
                  setUserData({ ...userData, contraseñaActual: e.target.value })
                }
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="nuevaContraseña"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Nueva Contraseña:
              </label>
              <input
                type="password"
                className="form-control"
                id="nuevaContraseña"
                value={userData.nuevaContraseña}
                onChange={(e) =>
                  setUserData({ ...userData, nuevaContraseña: e.target.value })
                }
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="confirmarContraseña"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Confirmar Contraseña:
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmarContraseña"
                value={userData.confirmarContraseña}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    confirmarContraseña: e.target.value,
                  })
                }
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Guardar Cambios
            </button>
          </form>

          {/*<hr style={{ borderColor: "#fff" }} />

          <h2 style={{ color: '#fff' }}>Modo Claro / Oscuro</h2>
          <div className="form-check form-switch" style={{ color: '#fff' }}>
              <input className="form-check-input" type="checkbox" checked={modoOscuro} onChange={() => setModoOscuro(!modoOscuro)} id="modoOscuroSwitch" />
              <label className="form-check-label" htmlFor="modoOscuroSwitch">Modo Oscuro</label>
          </div>*/}
        </div>
      </div>
    </div>
  );
}

export default Configuration;
