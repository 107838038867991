import React from "react";
import { Container, Card, ListGroup } from "react-bootstrap";

const NewsSection = () => {
  return (
    <div
      style={{
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: 20,
        padding: "10px 0"
      }}
    >
      <Container>
        <h2 className="mb-4" style={{ color: "#fff" }}>
          Novedades
        </h2>
        <Card style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Card>
                <Card.Body>
                  <Card.Title>
                    Novedades: Presentamos Nuestro Chat Integrado para una
                    Comunicación Directa
                  </Card.Title>
                  <br />
                  <Card.Text>
                    <p style={{ color: "#000" }}>
                      En Near-U, nos complace anunciar una mejora significativa
                      en la experiencia de nuestros usuarios. Hemos implementado
                      un chat integrado, una herramienta poderosa que facilita
                      la comunicación directa entre clientes y proveedores de
                      servicios.
                    </p>
                    <br />
                    <p style={{ color: "#000" }}>
                      <b>¿Qué puedes esperar del Chat Integrado de Near-U?</b>
                    </p>
                    <ul style={{ color: "#000" }}>
                      <li>
                        <b>Comunicación Instantánea: </b>Con nuestro chat
                        integrado, los usuarios pueden comunicarse de manera
                        instantánea, eliminando barreras de tiempo y distancia.
                      </li>
                      <li>
                        <b>Facilita la Interacción: </b>Ya no necesitas buscar
                        correos electrónicos o números de teléfono. Nuestro chat
                        te permite comunicarte sin esfuerzo con los proveedores
                        de servicios en tiempo real.
                      </li>
                      <li>
                        <b>Seguridad y Confianza: </b>Todos los mensajes dentro
                        de la plataforma están protegidos, brindando seguridad y
                        tranquilidad a nuestros usuarios.
                      </li>
                      <li>
                        <b>Organización Simplificada: </b>Mantén un seguimiento
                        claro de todas las conversaciones y acuerdos en un solo
                        lugar, mejorando la organización y la gestión de las
                        transacciones.
                      </li>
                    </ul>
                    <br />
                    <p style={{ color: "#000" }}>
                      <b>¿Qué puedes esperar del Chat Integrado de Near-U?</b>
                    </p>
                    <ol style={{ color: "#000" }}>
                      <li>
                        <b>Inicia Sesión y Explora: </b>Ingresa a tu cuenta
                        Near-U y explora la amplia gama de servicios
                        disponibles.
                      </li>
                      <li>
                        <b>Contacta a los Proveedores: </b>Una vez que
                        encuentres un servicio que te interese, simplemente haz
                        clic en el botón de 'Enviar Mensaje' y
                        comienza la conversación.
                      </li>
                      <li>
                        <b>Comunícate Sin Barreras: </b>Pregunta, negocia
                        detalles, o coordina directamente los trabajos a través
                        de nuestro chat, todo de manera fácil y rápida.
                      </li>
                    </ol>
                    <br />
                    <p style={{ color: "#000" }}>
                      <b>¿Qué puedes esperar del Chat Integrado de Near-U?</b>
                    </p>
                    <p style={{ color: "#000" }}>
                      En Near-U, nos esforzamos por mejorar continuamente la
                      experiencia de nuestros usuarios. Nuestro chat integrado
                      es solo el comienzo de una serie de actualizaciones
                      diseñadas para simplificar y mejorar la interacción entre
                      clientes y proveedores de servicios.
                    </p>
                    <p style={{ color: "#000" }}>
                      ¡Únete a Near-U y descubre cómo nuestro chat integrado
                      revoluciona la forma en que te conectas con los servicios
                      que necesitas!
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Container>
    </div>
  );
};

export default NewsSection;
