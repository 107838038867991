import React from "react";
import LogoGrande from "../img/logo_negro_lg.svg";
import About from "./About";
import Novedades from "./Novedades";

function Inicio() {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: "100%",
          height: "calc(100dvh - 252px)",
          background: "linear-gradient(270deg, #375189, #308ab4)",
          borderRadius: "0 0 20px 20px",
        }}
      >
        <div className="col-md-12 d-flex flex-row align-items-center justify-content-center gap-5 container_inicio">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <h1
              style={{
                color: "#d5d7d8",
                fontSize: 60,
                position: "relative",
                overflow: "hidden",
              }}
            >
              NEAR-
              <span
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "25px",
                  transform: "translateX(-50%)",
                  width: "100%",
                  height: "10px",
                  background: "linear-gradient(90deg, #3181ad, #3278a7)",
                }}
              ></span>
              U
            </h1>
            <h3 style={{ color: "#fff" }}>SIEMPRE CERCA TUYO</h3>
          </div>
          <div className="col-md-3 img-inicio">
            <img
              style={{ opacity: 0.5 }}
              class="img-fluid"
              src={LogoGrande}
              alt="Logo Near-U Grande"
            />
          </div>
        </div>
      </div>
      <About />
      <br />
      <Novedades />
    </>
  );
}

export default Inicio;
