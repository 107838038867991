import { useState } from "react";
import LogoGrande from "../img/logo_negro_lg.svg";
import Select from "react-select";

const paises = [
  { codigo: "us", nombre: "Estados Unidos", codigoTelefono: "+1" },
  { codigo: "ar", nombre: "Argentina", codigoTelefono: "+54" },
  { codigo: "au", nombre: "Australia", codigoTelefono: "+61" },
  { codigo: "br", nombre: "Brasil", codigoTelefono: "+55" },
  { codigo: "cl", nombre: "Chile", codigoTelefono: "+56" },
  { codigo: "mx", nombre: "México", codigoTelefono: "+52" },
  { codigo: "pe", nombre: "Perú", codigoTelefono: "+51" },
  { codigo: "fr", nombre: "Francia", codigoTelefono: "+33" },
  { codigo: "de", nombre: "Alemania", codigoTelefono: "+49" },
  { codigo: "it", nombre: "Italia", codigoTelefono: "+39" },
  { codigo: "nl", nombre: "Países Bajos", codigoTelefono: "+31" },
  { codigo: "es", nombre: "España", codigoTelefono: "+34" },
  { codigo: "ch", nombre: "Suiza", codigoTelefono: "+41" },
  { codigo: "se", nombre: "Suecia", codigoTelefono: "+46" },
  { codigo: "no", nombre: "Noruega", codigoTelefono: "+47" },
  { codigo: "dk", nombre: "Dinamarca", codigoTelefono: "+45" },
  { codigo: "fi", nombre: "Finlandia", codigoTelefono: "+358" },
  { codigo: "ru", nombre: "Rusia", codigoTelefono: "+7" },
  { codigo: "jp", nombre: "Japón", codigoTelefono: "+81" },
  { codigo: "cn", nombre: "China", codigoTelefono: "+86" },
  { codigo: "in", nombre: "India", codigoTelefono: "+91" },
  { codigo: "kr", nombre: "Corea del Sur", codigoTelefono: "+82" },
  { codigo: "il", nombre: "Israel", codigoTelefono: "+972" },
  { codigo: "sa", nombre: "Arabia Saudita", codigoTelefono: "+966" },
  { codigo: "za", nombre: "Sudáfrica", codigoTelefono: "+27" },
  { codigo: "eg", nombre: "Egipto", codigoTelefono: "+20" },
  { codigo: "ng", nombre: "Nigeria", codigoTelefono: "+234" },
  { codigo: "ke", nombre: "Kenia", codigoTelefono: "+254" },
  { codigo: "tr", nombre: "Turquía", codigoTelefono: "+90" },
  { codigo: "gr", nombre: "Grecia", codigoTelefono: "+30" },
  { codigo: "cz", nombre: "República Checa", codigoTelefono: "+420" },
  { codigo: "pl", nombre: "Polonia", codigoTelefono: "+48" },
  { codigo: "ua", nombre: "Ucrania", codigoTelefono: "+380" },
  { codigo: "hu", nombre: "Hungría", codigoTelefono: "+36" },
  { codigo: "at", nombre: "Austria", codigoTelefono: "+43" },
  { codigo: "be", nombre: "Bélgica", codigoTelefono: "+32" },
  { codigo: "pt", nombre: "Portugal", codigoTelefono: "+351" },
  { codigo: "ie", nombre: "Irlanda", codigoTelefono: "+353" },
  { codigo: "gb", nombre: "Reino Unido", codigoTelefono: "+44" },
  { codigo: "nz", nombre: "Nueva Zelanda", codigoTelefono: "+64" },
  { codigo: "sg", nombre: "Singapur", codigoTelefono: "+65" },
  { codigo: "my", nombre: "Malasia", codigoTelefono: "+60" },
  { codigo: "id", nombre: "Indonesia", codigoTelefono: "+62" },
  { codigo: "ph", nombre: "Filipinas", codigoTelefono: "+63" },
  { codigo: "vn", nombre: "Vietnam", codigoTelefono: "+84" },
];

function Contact() {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [codigoTelefono, setCodigoTelefono] = useState(null);
  const [telefono, setTelefono] = useState("");
  const [mensajeEnviado, setMensajeEnviado] = useState(false);

  const handleEnviarMensaje = (e) => {
    e.preventDefault();
    // Aquí podrías implementar la lógica para enviar el mensaje,
    // como enviar un correo electrónico o guardar en una base de datos.
    // Por ahora, simplemente marcamos el mensaje como enviado.
    setMensajeEnviado(true);

    // Puedes agregar más lógica aquí, como reiniciar los campos del formulario.
    setNombre("");
    setEmail("");
    setMensaje("");

    // Espera un segundo antes de reiniciar el estado
    setTimeout(() => {
      setMensajeEnviado(false);
    }, 1000);
  };

  return (
    <div
      style={{
        width: "100%",
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
        padding: 20,
      }}
    >
      <div className="row align-items-center justify-content-center gap-5">
        <div className="col-lg-5">
          <h1
            className="mt-5 mb-4"
            style={{ color: "#fff", textAlign: "center" }}
          >
            Contacto
          </h1>

          {mensajeEnviado ? (
            <div className="alert alert-success" role="alert">
              ¡Mensaje enviado con éxito!
            </div>
          ) : (
            <form onSubmit={handleEnviarMensaje} className="mb-5">
              <div className="mb-3">
                <label
                  htmlFor="nombre"
                  className="form-label"
                  style={{ color: "#fff" }}
                >
                  Nombre:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="form-label"
                  style={{ color: "#fff" }}
                >
                  Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="email"
                  className="form-label"
                  style={{ color: "#fff" }}
                >
                  Número de Teléfono:
                </label>
                <div className="col d-flex align-items-center">
                  <Select
                    options={paises.map((pais) => ({
                      value: pais,
                      label: (
                        <div className="d-flex align-items-center gap-1">
                          <img
                            src={`https://flagpedia.net/data/flags/w580/${pais.codigo}.webp`}
                            alt={`Bandera Pais ${pais.codigo}`}
                            style={{
                              width: 20,
                              height: 20,
                              borderRadius: 20,
                            }}
                          />
                          ({pais.codigoTelefono})
                        </div>
                      ),
                    }))}
                    value={paises.find(
                      (pais) => pais.codigo === codigoTelefono
                    )}
                    onChange={(selectedOption) => {
                      setCodigoTelefono(selectedOption.value);
                    }}
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="mensaje"
                  className="form-label"
                  style={{ color: "#fff" }}
                >
                  Mensaje:
                </label>
                <textarea
                  className="form-control"
                  id="mensaje"
                  rows="4"
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Enviar
              </button>
            </form>
          )}
        </div>
        <div className="col-lg-5">
          <img
            style={{ opacity: 0.5 }}
            class="img-fluid"
            src={LogoGrande}
            alt="Logo Near-U Grande"
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
