import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../img/logo_negro_sm.svg";
import { GetSingleApi, PostApi } from "../api/conection";

function NavMenu() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userFromStorage = JSON.parse(localStorage.getItem("user_id"));
        const res = await GetSingleApi("/user", userFromStorage);
        const user = res.data;
        setCurrentUser(user);
      } catch (err) {
        console.log(err);
      }
    };

    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/about" &&
      window.location.pathname !== "/contact"
    ) {
      fetchData();
    }

    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const handleIniciarSesion = () => {
    window.location.href = "/login";
  };
  const handleRegistrarse = () => {
    window.location.href = "/register";
  };
  const handleCerrarSesion = async () => {
    try {
      await PostApi("/logout", { id: currentUser.id });
      localStorage.removeItem("token");
      localStorage.removeItem("userCurrent");
      window.location.href = "/";
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  // const handleRoleChange = (role) => {
  //   console.log("Changing role to", role);
  // };

  return (
    <div className="container nav-menu">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
        <div className="col-md-3 mb-2 mb-md-0">
          <Link
            to={`${
              window.location.pathname === "/" ||
              window.location.pathname === "/login"
                ? "/"
                : "/home"
            }`}
            className="d-inline-flex link-body-emphasis text-decoration-none"
          >
            <h1 style={{ margin: 0, padding: 0, color: "#fff" }}>
              <img src={Logo} alt="Logo Near-u" height="100" />
            </h1>
          </Link>
        </div>

        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
          {currentPath === "/" ||
          currentPath === "/login" ||
          currentPath === "/about" ||
          currentPath === "/contact" ? (
            <>
              <li>
                <Link
                  to="/"
                  className={`nav-link px-2 ${
                    currentPath === "/" ? "link-primary" : "link-secondary"
                  }`}
                >
                  INICIO
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={`nav-link px-2 ${
                    currentPath === "/about" ? "link-primary" : "link-secondary"
                  }`}
                >
                  ACERCA DE
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className={`nav-link px-2 ${
                    currentPath === "/contact"
                      ? "link-primary"
                      : "link-secondary"
                  }`}
                >
                  CONTACTO
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to="/home"
                  className={`nav-link px-2 ${
                    currentPath === "/home" ? "link-primary" : "link-secondary"
                  }`}
                >
                  INICIO
                </Link>
              </li>
              <li>
                <Link
                  to="/profile"
                  className={`nav-link px-2 ${
                    currentPath === "/profile"
                      ? "link-primary"
                      : "link-secondary"
                  }`}
                >
                  PERFIL
                </Link>
              </li>
              <li>
                <Link
                  to="/chat"
                  className={`nav-link px-2 ${
                    currentPath === "/profile"
                      ? "link-primary"
                      : "link-secondary"
                  }`}
                >
                  CHAT
                </Link>
              </li>
              <li>
                <Link
                  to="/contacto"
                  className={`nav-link px-2 ${
                    currentPath === "/contacto"
                      ? "link-primary"
                      : "link-secondary"
                  }`}
                >
                  CONTACTO
                </Link>
              </li>
            </>
          )}
        </ul>
        {currentPath === "/" ||
        currentPath === "/login" ||
        currentPath === "/about" ||
        currentPath === "/contact" ? (
          <div className="d-flex align-items-center gap-5">
            <button onClick={handleRegistrarse} className="btn btn-primary">
              CREAR CUENTA
            </button>
            <button
              onClick={handleIniciarSesion}
              className="btn btn-outline-primary"
            >
              INGRESAR
            </button>
          </div>
        ) : (
          <div className="dropdown col-md-3 text-end">
            <div className="position-relative">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={
                    currentUser?.image
                      ? `https://api.near-u.top${currentUser?.image}`
                      : "https://tse3.mm.bing.net/th?id=OIP.6Zu4MaXfS1aM1EdA31OweQAAAA&pid=Api&P=0&h=180"
                  }
                  alt="mdo"
                  width="32"
                  height="32"
                  className="rounded-circle"
                />
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <Link to="/configuration" className="dropdown-item">
                    Configuración
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider" />
                </li>
                {/* <li className="dropdown-submenu">
                  <button
                    className="dropdown-item dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Cambiar Rol
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleRoleChange("admin")}
                      >
                        Admin
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleRoleChange("proveedor")}
                      >
                        Proveedor
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleRoleChange("comprador")}
                      >
                        Comprador
                      </button>
                    </li>
                  </ul>
                </li> */}
                {/* <li>
                  <div className="dropdown-divider" />
                </li> */}
                <li>
                  <button
                    className="dropdown-item"
                    onClick={handleCerrarSesion}
                  >
                    Cerrar Sesión
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default NavMenu;
