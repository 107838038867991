import { useState } from "react";
import { GetSingleApi, PostApi } from "../api/conection";

function Login() {
  const [cliente, setCliente] = useState({
    Email: "",
    Contraseña: "",
    MensajeError: "",
  });

  const handleRegistrarse = () => {
    window.location.href = "/register";
  };

  const iniciarSesion = async () => {
    try {
      const response = await PostApi("/login", {
        email: cliente.Email,
        password: cliente.Contraseña,
      });

      localStorage.setItem("token", response.access_token);
      await GetSingleApi("/user", response.user.id)
        .then((res) => {
          localStorage.setItem("userCurrente", JSON.stringify(res.data));
          localStorage.setItem("user_id", JSON.stringify(res.data.id));

          window.location.href = "/home";
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error("Error during login:", error);
      setCliente({ MensajeError: "Credenciales incorrectas" });
    }
  };

  return (
    <div
      className="col-xl-12 col-xxl-8 px-4 py-5 d-flex flex-column justify-content-center"
      style={{
        width: "100%",
        height: "100dvh",
        background: "linear-gradient(270deg, #375189, #308ab4)",
      }}
    >
      <div className="row align-items-center g-lg-5 py-5">
        <div className="col-md-12 mx-auto col-lg-5">
          {cliente.MensajeError && (
            <div className="alert alert-danger" role="alert">
              {cliente.MensajeError}
            </div>
          )}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              iniciarSesion();
            }}
            style={{
              background: "#fff",
              padding: "50px 20px 10px",
              borderRadius: 20,
            }}
          >
            {/* <h2 style={{ color: "#000", textAlign: "center" }}>
              Iniciar Sesión
            </h2>
            <br /> */}
            <div className="form-floating mb-3">
              <div className="col-md-12 d-flex flex-row align-items-center justify-content-between login">
                <div className="col-md-6 d-flex flex-column align-items-center gap-3">
                  <div className="col-md-12 d-flex flex-row align-items-center gap-3">
                    <input
                      style={{
                        background: "transparent",
                        color: "#000",
                        borderColor: "#000",
                      }}
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Email"
                      value={cliente.Email}
                      onChange={(e) =>
                        setCliente({ ...cliente, Email: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 d-flex flex-row align-items-center gap-3">
                    <input
                      style={{
                        background: "transparent",
                        color: "#000",
                        borderColor: "#000",
                      }}
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Contraseña"
                      value={cliente.Contraseña}
                      onChange={(e) =>
                        setCliente({ ...cliente, Contraseña: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center gap-3">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Iniciar Sesión
                  </button>
                  <label style={{ color: "#000" }}>
                    <input
                      style={{ background: "transparent" }}
                      type="checkbox"
                      value="remember-me"
                    />{" "}
                    Recordarme
                  </label>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="col-md-12 d-flex justify-content-center">
              <button
                onClick={handleRegistrarse}
                className="btn btn-lg btn-outline-primary"
                type="submit"
              >
                Crear Cuenta
              </button>
            </div>
            <br />
            <small
              style={{
                display: "block",
                width: "100%",
                color: "#000",
                textAlign: "center",
              }}
            >
              Al hacer clic en Iniciar Sesión, acepta los términos y
              condiciones.
            </small>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
