import { useState } from "react";
import Select from "react-select";
import { GetOther, PostApi } from "../api/conection";

const paises = [
  { codigo: "us", nombre: "Estados Unidos", codigoTelefono: "+1" },
  { codigo: "ar", nombre: "Argentina", codigoTelefono: "+54" },
  { codigo: "au", nombre: "Australia", codigoTelefono: "+61" },
  { codigo: "br", nombre: "Brasil", codigoTelefono: "+55" },
  { codigo: "cl", nombre: "Chile", codigoTelefono: "+56" },
  { codigo: "mx", nombre: "México", codigoTelefono: "+52" },
  { codigo: "pe", nombre: "Perú", codigoTelefono: "+51" },
  { codigo: "fr", nombre: "Francia", codigoTelefono: "+33" },
  { codigo: "de", nombre: "Alemania", codigoTelefono: "+49" },
  { codigo: "it", nombre: "Italia", codigoTelefono: "+39" },
  { codigo: "nl", nombre: "Países Bajos", codigoTelefono: "+31" },
  { codigo: "es", nombre: "España", codigoTelefono: "+34" },
  { codigo: "ch", nombre: "Suiza", codigoTelefono: "+41" },
  { codigo: "se", nombre: "Suecia", codigoTelefono: "+46" },
  { codigo: "no", nombre: "Noruega", codigoTelefono: "+47" },
  { codigo: "dk", nombre: "Dinamarca", codigoTelefono: "+45" },
  { codigo: "fi", nombre: "Finlandia", codigoTelefono: "+358" },
  { codigo: "ru", nombre: "Rusia", codigoTelefono: "+7" },
  { codigo: "jp", nombre: "Japón", codigoTelefono: "+81" },
  { codigo: "cn", nombre: "China", codigoTelefono: "+86" },
  { codigo: "in", nombre: "India", codigoTelefono: "+91" },
  { codigo: "kr", nombre: "Corea del Sur", codigoTelefono: "+82" },
  { codigo: "il", nombre: "Israel", codigoTelefono: "+972" },
  { codigo: "sa", nombre: "Arabia Saudita", codigoTelefono: "+966" },
  { codigo: "za", nombre: "Sudáfrica", codigoTelefono: "+27" },
  { codigo: "eg", nombre: "Egipto", codigoTelefono: "+20" },
  { codigo: "ng", nombre: "Nigeria", codigoTelefono: "+234" },
  { codigo: "ke", nombre: "Kenia", codigoTelefono: "+254" },
  { codigo: "tr", nombre: "Turquía", codigoTelefono: "+90" },
  { codigo: "gr", nombre: "Grecia", codigoTelefono: "+30" },
  { codigo: "cz", nombre: "República Checa", codigoTelefono: "+420" },
  { codigo: "pl", nombre: "Polonia", codigoTelefono: "+48" },
  { codigo: "ua", nombre: "Ucrania", codigoTelefono: "+380" },
  { codigo: "hu", nombre: "Hungría", codigoTelefono: "+36" },
  { codigo: "at", nombre: "Austria", codigoTelefono: "+43" },
  { codigo: "be", nombre: "Bélgica", codigoTelefono: "+32" },
  { codigo: "pt", nombre: "Portugal", codigoTelefono: "+351" },
  { codigo: "ie", nombre: "Irlanda", codigoTelefono: "+353" },
  { codigo: "gb", nombre: "Reino Unido", codigoTelefono: "+44" },
  { codigo: "nz", nombre: "Nueva Zelanda", codigoTelefono: "+64" },
  { codigo: "sg", nombre: "Singapur", codigoTelefono: "+65" },
  { codigo: "my", nombre: "Malasia", codigoTelefono: "+60" },
  { codigo: "id", nombre: "Indonesia", codigoTelefono: "+62" },
  { codigo: "ph", nombre: "Filipinas", codigoTelefono: "+63" },
  { codigo: "vn", nombre: "Vietnam", codigoTelefono: "+84" },
];

function Registro() {
  const [cliente, setCliente] = useState({
    nombre: "",
    apellido: "",
    tipoDocumento: "",
    numeroDocumento: "",
    pais: "",
    telefono: "",
    email: "",
    provincia: "",
    distrito: "",
    contraseña: "",
    confirmarContraseña: "",
    mensajeError: "",
  });

  const Registrarse = async () => {
    let coordenadas = null;
    if (cliente.contraseña !== cliente.confirmarContraseña) {
      setCliente({ ...cliente, mensajeError: "Las contraseñas no coinciden" });
      return;
    }
    await GetOther(
      `https://api.opencagedata.com/geocode/v1/json?q=${cliente.distrito.replace(
        /\s+/g,
        "%20"
      )}%20${cliente.provincia.replace(
        /\s+/g,
        "%20"
      )}%20${cliente.pais.nombre.replace(
        /\s+/g,
        "%20"
      )}&key=a08ab980938843789855fc7e05f161d3&no_annotations=1&language=es`
    )
      .then(
        (res) =>
          (coordenadas = `${res.data.results.geometry.lat}, ${res.data.results.geometry.lng}`)
      )
      .catch((err) => console.error(err));
    try {
      const response = await PostApi("/register", {
        firstname: cliente.nombre,
        lastname: cliente.apellido,
        tipo_documento: cliente.tipoDocumento,
        documento: cliente.numeroDocumento,
        country: cliente.pais.nombre,
        codigo_telefono: cliente.pais.codigoTelefono,
        phone: cliente.telefono,
        email: cliente.email,
        province: cliente.provincia,
        district: cliente.distrito,
        contraseña: cliente.contraseña,
        coordenadas: coordenadas,
      });

      localStorage.setItem("token", response.access_token);
      localStorage.setItem("userCurrent", JSON.stringify(response.user));

      window.location.href = "/home";
    } catch (error) {
      console.error("Error during registration:", error);
      setCliente({ mensajeError: "Error al registrar" });
    }
  };

  return (
    <div
      className="col-xl-12 col-xxl-8 px-4 py-5 d-flex flex-column justify-content-center"
      style={{
        width: "100%",
        height: "100vh",
        background: "linear-gradient(270deg, #375189, #308ab4)",
      }}
    >
      <div className="row align-items-center g-lg-5 py-5">
        <div
          className="col-md-12 mx-auto col-lg-5 py-3 register"
          style={{
            background: "#fff",
            padding: "50px 20px 10px",
            borderRadius: 20,
            width: "70%",
          }}
        >
          {cliente.mensajeError && (
            <div className="alert alert-danger" role="alert">
              {cliente.mensajeError}
            </div>
          )}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              Registrarse();
            }}
          >
            <h2 style={{ color: "#364c8e", textAlign: "center" }}>
              INGRESA TUS DATOS Y ÚNETE A NEAR-U:
            </h2>
            <br />
            <div className="mb-3 row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre *"
                  value={cliente.nombre}
                  onChange={(e) =>
                    setCliente({ ...cliente, nombre: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido *"
                  value={cliente.apellido}
                  onChange={(e) =>
                    setCliente({ ...cliente, apellido: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col">
                <select
                  className="form-select"
                  value={cliente.tipoDocumento}
                  onChange={(e) =>
                    setCliente({ ...cliente, tipoDocumento: e.target.value })
                  }
                  required
                >
                  <option disabled selected value="">
                    Tipo de Documento *
                  </option>
                  <option value="dni">DNI</option>
                  <option value="pasaporte">Pasaporte</option>
                </select>
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Número de Documento *"
                  value={cliente.numeroDocumento}
                  onChange={(e) =>
                    setCliente({
                      ...cliente,
                      numeroDocumento: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col d-flex align-items-center">
                <Select
                  options={paises.map((pais) => ({
                    value: pais,
                    label: (
                      <div className="d-flex align-items-center gap-1">
                        <img
                          src={`https://flagpedia.net/data/flags/w580/${pais.codigo}.webp`}
                          alt={`Bandera Pais ${pais.codigo}`}
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                          }}
                        />
                        ({pais.codigoTelefono})
                      </div>
                    ),
                  }))}
                  value={paises.find((pais) => pais.codigo === cliente.pais)}
                  onChange={(selectedOption) => {
                    setCliente({ ...cliente, pais: selectedOption.value });
                  }}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Número de Teléfono *"
                  value={cliente.telefono}
                  onChange={(e) =>
                    setCliente({ ...cliente, telefono: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Correo Electrónico *"
                  value={cliente.email}
                  onChange={(e) =>
                    setCliente({ ...cliente, email: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Provincia *"
                  value={cliente.provincia}
                  onChange={(e) =>
                    setCliente({ ...cliente, provincia: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Distrito *"
                  value={cliente.distrito}
                  onChange={(e) =>
                    setCliente({
                      ...cliente,
                      distrito: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Contraseña *"
                  value={cliente.contraseña}
                  onChange={(e) =>
                    setCliente({ ...cliente, contraseña: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirmar Contraseña *"
                  value={cliente.confirmarContraseña}
                  onChange={(e) =>
                    setCliente({
                      ...cliente,
                      confirmarContraseña: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="d-grid">
              <button className="btn btn-primary" type="submit">
                Registrarse
              </button>
            </div>
          </form>
          <hr className="my-4" />
          <div className="d-grid">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              YA TENGO CUENTA
            </button>
          </div>
          <br />
          <small
            style={{
              display: "block",
              width: "100%",
              color: "#000",
              textAlign: "center",
            }}
          >
            Al hacer clic en Registrarse, acepta los términos y condiciones.
          </small>
        </div>
      </div>
    </div>
  );
}

export default Registro;
