import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Contact from "./components/Contact";
import Configuration from "./components/Configuration";
import Inicio from "./components/Inicio";
import Home from "./components/Home";
import NavMenu from "./components/NavMenu";
import Footer from "./components/Footer";
import "./App.css";
import Registro from "./components/Registro";
import AcercaDe from "./components/AcercaDe";
import ChatApp from "./components/ChatApp";

function App() {
  const isLogin = window.location.pathname === "/login";
  const isRegister = window.location.pathname === "/register";

  return (
    <Router>
      <div className="App">
        {!isLogin && !isRegister && <NavMenu />}
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registro />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<AcercaDe />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/chat" element={<ChatApp />} />
        </Routes>
        {!isLogin && !isRegister && <Footer />}
      </div>
    </Router>
  );
}

export default App;
