import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/About.css";

const cardsData = [
  {
    title: "¿Quiénes somos?",
    description:
      "Near-U es una plataforma diseñada para acercar a la comunidad local a una amplia gama de servicios profesionales. Desde programadores y diseñadores gráficos hasta albañiles, pintores y electricistas, ofrecemos una conexión directa entre aquellos que buscan habilidades específicas y los talentosos proveedores de servicios de nuestra comunidad.",
  },
  {
    title: "Experiencia Confiable",
    description:
      "Nuestra misión es proporcionar una experiencia transparente y confiable para clientes y proveedores por igual. Con un sistema de calificación basado en estrellas, los usuarios pueden evaluar la calidad del servicio recibido, garantizando una clasificación precisa y visible para cada proveedor.",
  },
  {
    title: "¿Qué Ofrecemos?",
    description: (
      <ul>
        <li>
          <b>Diversidad de Servicios: </b>Desde servicios técnicos y creativos
          hasta habilidades especializadas en el hogar, nuestra plataforma
          abarca una amplia gama de áreas para satisfacer todas las necesidades.
        </li>
        <li>
          <b>Evaluaciones Transparentes: </b>Las calificaciones y comentarios de
          los usuarios ofrecen una visión clara sobre la calidad y el compromiso
          de nuestros proveedores de servicios.
        </li>
      </ul>
    ),
  },
  {
    title: "Funcionalidades Destacadas",
    description: (
      <ul>
        <li>
          <b>Perfil Completo: </b>Cada proveedor cuenta con un perfil detallado
          que incluye información relevante, un portafolio de trabajos
          anteriores y la posibilidad de contacto directo a través de nuestro
          chat integrado.
        </li>
        <li>
          <b>Ubicación Relevante: </b>Con nuestro mapa interactivo, los usuarios
          pueden explorar servicios cercanos, mostrando primero las opciones más
          convenientes y accesibles en su área.
        </li>
        <li>
          <b>Clasificación por Proximidad: </b>Mostramos los servicios más
          cercanos en las primeras páginas, permitiendo una búsqueda eficiente y
          rápida de proveedores locales.
        </li>
      </ul>
    ),
  },
  {
    title: "Nuestra Filosofía",
    description:
      "En Near-U, creemos en fomentar conexiones significativas dentro de las comunidades locales. Queremos facilitar la búsqueda de servicios confiables y de calidad, al tiempo que promovemos el desarrollo económico y profesional de los talentos locales.",
  },
  {
    title: "¡Bienvenido a Near-U!",
    description:
      "Únete a nosotros en Near-U y descubre la comodidad de encontrar servicios de calidad cerca de ti. ¡Bienvenido a la comunidad de Near-U, donde la excelencia se encuentra a la vuelta de la esquina!",
  },
];

const Card = ({ title, description }) => (
  <div className="card">
    <h3 style={{ textAlign: "center" }}>{title}</h3>
    <p style={{ color: "#000" }}>{description}</p>
  </div>
);

const About = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container" style={{ padding: 20 }}>
      <Slider {...sliderSettings}>
        {cardsData.map((card, index) => (
          <div key={index}>
            <Card title={card.title} description={card.description} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default About;
