import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { GetApi, GetSingleApi, PostApi } from "../api/conection";
import Logo from "../img/logo_negro_sm.svg";

function Home() {
  const [currentPath, setCurrentPath] = useState(null);
  const [servicios, setServicios] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await GetSingleApi("/user", localStorage.user_id);
        const user = res.data;
        setCurrentUser(user);

        const servicesRes = await GetApi("/services");
        const services = servicesRes.data;

        for (const service of services) {
          const userRes = await GetSingleApi("/user", service.user_id);
          const userData = userRes.data;

          const servicesRequestsRes = await GetApi(`/service_request`);
          const servicesRequestsData = servicesRequestsRes.data.filter(
            (serviceRequest) => serviceRequest.service_id === service.id
          );

          const totalRatings = servicesRequestsData.reduce(
            (acc, curr) => acc + curr.rating,
            0
          );
          const averageRating = totalRatings / servicesRequestsData.length;

          const clientesRealizados = servicesRequestsData.filter(
            (request) => request.activo === 0 && request.terminado === 1
          ).length;
          const clientesEnProceso = servicesRequestsData.filter(
            (request) => request.activo === 1 && request.terminado === null
          ).length;

          service.vendedor = userData;
          service.rating = averageRating || 0;
          service.clientesRealizados = clientesRealizados;
          service.clientesEnProceso = clientesEnProceso;
        }

        setServicios(services);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    fetchData();

    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const createChat = (servicio) => {
    if (servicio.vendedor.id !== currentUser.id) {
      const data = {
        user_id_client: currentUser.id,
        user_id_provider: servicio.vendedor.id,
        servicio: servicio.nombre,
      };
      PostApi("/chat", data)
        .then(
          (res) =>
            (window.location.href = "/chat?selected-chat=" + res.servicio.id)
        )
        .catch((err) => console.error(err));
    } else {
      alert("No puedes enviarte mensaje a ti mismo.");
    }
  };

  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroFecha, setFiltroFecha] = useState("");
  const [filtroTipo, setFiltroTipo] = useState("");
  const [filtroCercania, setFiltroCercania] = useState("desc");
  const [filtroValoracion, setFiltroValoracion] = useState("");

  const [mostrarFiltros, setMostrarFiltros] = useState(false);

  const toggleFiltros = () => {
    setMostrarFiltros(!mostrarFiltros);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radio de la Tierra en kilómetros
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distancia en kilómetros
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const serviciosFiltrados =
    servicios &&
    servicios
      .filter((servicio) => {
        let resultado = true;

        if (
          filtroNombre &&
          !servicio?.nombre.toLowerCase().includes(filtroNombre.toLowerCase())
        ) {
          resultado = false;
        }

        if (
          filtroTipo &&
          servicio?.tipo.toLowerCase() !== filtroTipo.toLowerCase()
        ) {
          resultado = false;
        }

        return resultado;
      })
      .sort((a, b) => {
        const coordenadasCurrentUser = currentUser?.coordenadas.split(", ");
        const coordenadasVendedorA = a?.vendedor?.coordenadas.split(", ");
        const coordenadasVendedorB = b?.vendedor?.coordenadas.split(", ");

        const distanciaA = calculateDistance(
          coordenadasCurrentUser[0],
          coordenadasCurrentUser[1],
          coordenadasVendedorA[0],
          coordenadasVendedorA[1]
        );

        const distanciaB = calculateDistance(
          coordenadasCurrentUser[0],
          coordenadasCurrentUser[1],
          coordenadasVendedorB[0],
          coordenadasVendedorB[1]
        );

        a.km = distanciaA;
        b.km = distanciaB;

        return filtroCercania === "desc"
          ? distanciaA - distanciaB
          : distanciaB - distanciaA;
      })
      .sort((a, b) => {
        const dateA = new Date(a?.created_at);
        const dateB = new Date(b?.created_at);

        if (!isNaN(dateA) && !isNaN(dateB)) {
          if (filtroFecha === "desc") {
            return dateA - dateB;
          } else if (filtroFecha === "asc") {
            return dateB - dateA;
          }
        } else if (!isNaN(dateA)) {
          return -1;
        } else if (!isNaN(dateB)) {
          return 1;
        }

        return 0;
      })
      .sort((a, b) => {
        let comparador = 0;

        if (filtroValoracion === "asc") {
          comparador = a?.rating - b?.rating;
        } else if (filtroValoracion === "desc") {
          comparador = b?.rating - a?.rating;
        }

        return comparador;
      });

  function normalizeString(string) {
    const normalizedString = string
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    return normalizedString.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const serviciosPerPage = 12;

  // Obtener índices de servicios actuales
  const indexOfLastServicio = currentPage * serviciosPerPage;
  const indexOfFirstServicio = indexOfLastServicio - serviciosPerPage;
  const currentServicios =
    serviciosFiltrados &&
    serviciosFiltrados.slice(indexOfFirstServicio, indexOfLastServicio);

  // Cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
      }}
    >
      <h1 style={{ textAlign: "center", color: "#000" }}>NEAR-U</h1>
      <div
        className="album py-5"
        style={{ backgroundColor: "#fff", borderRadius: 20 }}
      >
        <h3
          className="h3-home"
          style={{ textAlign: "center", color: "#000", fontSize: "xxx-large" }}
        >
          ¿QUÉ TRABAJO ESTAS BUSCANDO?
        </h3>
        <br />
        <div className="container">
          {/* Botón de filtros */}
          <div className="row mb-3" style={{ textAlignLast: "start" }}>
            <div className="col-md-12 text-center">
              <button
                className="btn btn-outline-primary"
                onClick={toggleFiltros}
              >
                Mostrar/Ocultar Filtros
              </button>
            </div>
          </div>
          {/* Filtros */}
          <div
            className="col-md-12 row mb-3"
            style={{ display: mostrarFiltros ? "flex" : "none" }}
          >
            <div className="col-md-4">
              <label style={{ color: "#000" }}>Nombre del servicio:</label>
              <select
                className="form-select"
                value={filtroNombre}
                onChange={(e) => setFiltroNombre(e.target.value)}
              >
                <option value="">Todos</option>
                {servicios &&
                  servicios
                    .map((servicio) => normalizeString(servicio?.nombre))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((nombre, index) => (
                      <option key={index} value={nombre}>
                        {nombre}
                      </option>
                    ))}
              </select>
            </div>
            <div className="col-md-4">
              <label style={{ color: "#000" }}>Fecha de publicación:</label>
              <select
                className="form-select"
                value={filtroFecha}
                onChange={(e) => setFiltroFecha(e.target.value)}
              >
                <option value="">Todos</option>
                <option value="desc">Menor a mayor</option>
                <option value="asc">Mayor a menor</option>
              </select>
            </div>
            <div className="col-md-4">
              <label style={{ color: "#000" }}>Tipo de trabajo:</label>
              <select
                className="form-select"
                value={filtroTipo}
                onChange={(e) => setFiltroTipo(e.target.value)}
              >
                <option value="">Todos</option>
                <option value="remoto">Remoto</option>
                <option value="presencial">Presencial</option>
              </select>
            </div>
          </div>
          <div
            className="col-md-12 row mb-3"
            style={{ display: mostrarFiltros ? "flex" : "none" }}
          >
            <div className="col-md-4">
              <label style={{ color: "#000" }}>Ordenar por cercanía:</label>
              <select
                className="form-select"
                value={filtroCercania}
                onChange={(e) => setFiltroCercania(e.target.value)}
              >
                <option value="desc">Más cercano</option>
                <option value="asc">Más lejano</option>
              </select>
            </div>
            <div className="col-md-4">
              <label style={{ color: "#000" }}>Ordenar por valoración:</label>
              <select
                className="form-select"
                value={filtroValoracion}
                onChange={(e) => setFiltroValoracion(e.target.value)}
              >
                <option value="">Todos</option>
                <option value="desc">Más valorado</option>
                <option value="asc">Menos valorado</option>
              </select>
            </div>
          </div>
          <br />
          <br />
          {loading && !currentServicios && (
            <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
              <img src={Logo} alt="Logo Near-u" height="100" id="loader" />
              <h4>Cargando...</h4>
            </div>
          )}
          {/* Lista de servicios filtrados */}
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            {currentServicios &&
              currentServicios.map((servicio, index) => {
                return (
                  <div className="col" key={index}>
                    <div
                      className="card shadow-sm"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <a
                        className="text-decoration-none"
                        href={`/profile/${servicio?.vendedor?.username}`}
                      >
                        <img
                          src={
                            servicio?.vendedor?.image
                              ? `https://api.near-u.top${servicio?.vendedor?.image}`
                              : "https://tse3.mm.bing.net/th?id=OIP.6Zu4MaXfS1aM1EdA31OweQAAAA&pid=Api&P=0&h=180"
                          }
                          className="card-img-top rounded-circle mx-auto d-block mt-3"
                          alt="User Image"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                        <p
                          style={{
                            color: "#000",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {servicio?.vendedor?.firstname}{" "}
                          {servicio?.vendedor?.lastname}
                        </p>
                      </a>
                      <div className="card-body">
                        <h5 className="card-title" style={{ color: "#000" }}>
                          {servicio?.nombre}
                        </h5>
                        <p className="card-text" style={{ color: "#000" }}>
                          {servicio?.description}
                        </p>
                        <p className="card-text" style={{ color: "#000" }}>
                          Trabajos realizados: {servicio?.clientesRealizados}{" "}
                          <br />
                          Trabajos pendientes: {servicio?.clientesEnProceso}
                          <br />
                          Distancia del servicio:{" "}
                          {servicio?.km
                            ? servicio.km.toFixed(2)
                            : servicio?.km || 0}
                          km
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className="btn-group d-flex flex-column"
                            style={{ gap: "0.5em" }}
                          >
                            <a
                              href={`/profile/${servicio?.vendedor?.username}`}
                              type="button"
                              className="btn btn-sm btn-outline-primary"
                            >
                              Ver Perfil
                            </a>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-secondary"
                              onClick={() => createChat(servicio)}
                            >
                              Enviar Mensaje
                            </button>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            <span className="text-body-primary">
                              Calificación:&nbsp;
                            </span>
                            <p style={{ margin: 0, color: "#000" }}>
                              {servicio?.rating}/5
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* Paginación */}
          <div className="d-flex justify-content-center mt-3">
            <Pagination>
              {Array.from({
                length: Math.ceil(
                  servicios && servicios.length / serviciosPerPage
                ),
              }).map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
