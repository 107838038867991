import React from "react";
import "./css/AcercaDe.css";

function AcercaDe() {
  return (
    <div
      style={{
        width: "100%",
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
        padding: 20,
      }}
    >
      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Acerca de Near-U</h2>
        </div>
        <p>
          Near-U es una plataforma innovadora que tiene como objetivo principal
          conectar a personas con una amplia gama de servicios locales,
          simplificando la búsqueda y contratación de profesionales en diversas
          áreas. <br />
          <br />
          Nuestro compromiso radica en construir puentes entre clientes y
          talentosos proveedores de servicios, desde programadores y diseñadores
          gráficos hasta albañiles, pintores y electricistas, facilitando así la
          satisfacción de necesidades específicas en cualquier comunidad.
        </p>
      </div>

      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Objetivos</h2>
        </div>
        <p>
          Nuestro propósito esencial es brindar una experiencia transparente y
          confiable tanto para clientes como para proveedores. <br />
          <br />
          Buscamos transformar la forma en que las personas acceden a servicios
          locales, fomentando conexiones sólidas y ayudando a los talentos de la
          comunidad a destacar en un mercado competitivo.
        </p>
      </div>

      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Logros</h2>
        </div>
        <ul>
          <li>
            Establecer una red sólida de proveedores de servicios altamente
            calificados y verificados.
          </li>
          <li>
            Facilitar miles de transacciones exitosas, asegurando la
            satisfacción de clientes y proveedores por igual.
          </li>
          <li>
            Implementar herramientas innovadoras, como nuestro chat integrado y
            un sistema de calificación basado en estrellas, para mejorar la
            experiencia del usuario.
          </li>
        </ul>
      </div>

      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>¿Qué Hacemos?</h2>
        </div>
        <ul>
          <li>En Near-U, trabajamos arduamente para:</li>
          <li>
            Proporcionar una plataforma intuitiva y fácil de usar que simplifica
            la búsqueda y contratación de servicios locales.
          </li>
          <li>
            Ofrecer a los proveedores de servicios una vitrina para presentar
            sus habilidades a una audiencia más amplia y potencialmente global.
          </li>
          <li>
            Garantizar la confianza y la calidad en cada transacción mediante
            sistemas de calificación y verificación, asegurando la satisfacción
            tanto del cliente como del proveedor.
          </li>
          <li>
            Fomentar el desarrollo económico y profesional de los talentos
            locales al promover su visibilidad y brindarles herramientas para
            expandir su base de clientes.
          </li>
        </ul>
      </div>

      <div className="descripcion-relativo bg-white rounded p-3 pt-inherit">
        <p>
          Near-U se compromete a seguir evolucionando y mejorando constantemente
          para continuar siendo la plataforma preferida para la conexión entre
          personas y servicios locales confiables y de calidad.
        </p>
      </div>
    </div>
  );
}

export default AcercaDe;
